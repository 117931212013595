import React, { Component } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import trackClickAnalytics from '../helpers/trackAnalytics';
class Carousel extends Component {
  constructor(props) {
    super(props);

    this.goToSlide = this.goToSlide.bind(this);
    this.goToPrevSlide = this.goToPrevSlide.bind(this);
    this.goToNextSlide = this.goToNextSlide.bind(this);

    this.state = {
      activeIndex: 0,
    };
    this.alt = this.props.alt;
  }

  /*  state = {
        activeIndex: 0
    }; */

  goToSlide(index) {
    this.setState({
      activeIndex: index,
    });
  }

  goToPrevSlide(e) {
    e.preventDefault();

    let index = this.state.activeIndex;
    let slides = this.props.children;

    let slidesLength = slides.length;

    if (index < 1) {
      index = slidesLength;
    }

    --index;
    trackClickAnalytics("Carrossel", "Click", "Ver anterior", index);

    this.setState({
      activeIndex: index,
    });
  }

  goToNextSlide(e) {
    // console.log('slideees');
    e.preventDefault();
    // console.log(e);
    // console.log(this.state);

    let index = this.state.activeIndex;
    let slides = this.props.children;

    let slidesLength = slides.length - 1;
    // console.log(slidesLength);

    if (index === slidesLength) {
      // console.log('entreeeeeeei');
      index = -1;
    }

    ++index;
    trackClickAnalytics("Carrossel", "Click", "Ver próximo", index);
    this.setState({
      activeIndex: index,
    });
  }

  render() {
    return (
      <div className='carousel'>
        <div
          className='carousel__arrow carousel__arrow--left'
          onClick={(e) => this.goToPrevSlide(e)}
        >
          <span className='fa fa-2x fa-angle-left' />
        </div>

        <ul className='carousel__slides'>
          {this.props.children.map((slide, index) => (
            <CarouselSlide
              key={index}
              index={index}
              activeIndex={this.state.activeIndex}
              slide={slide}
              alt={this.alt}
            />
          ))}
        </ul>
        <div
          className='carousel__arrow carousel__arrow--right'
          onClick={(e) => this.goToNextSlide(e)}
        >
          <span className='fa fa-2x fa-angle-right' />
        </div>

        {/*   <ul className="carousel__indicators">
          {this.props.children.map((slide, index) =>
            <CarouselIndicator
              key={index}
              index={index}
              activeIndex={this.state.activeIndex}
              isActive={this.state.activeIndex==index}
              onClick={e => this.goToSlide(index)}
            />
          )}
        </ul> */}
      </div>
    );
  }
}
const CarouselSlide = (props) => {
  var botPattern =
    '(googlebot/|bot|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis)';
  var regex = new RegExp(botPattern, 'i');
  var userAgent = typeof window !== 'undefined' && typeof window.navigator !== 'undefined'
    ? navigator.userAgent
    : '(googlebot/|bot|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis)';

  if (regex.test(userAgent)) {
    console.log('the user agent is a crawler!');
  }
  return (
    <li
      className={
        props.index === props.activeIndex
          ? 'carousel__slide carousel__slide--active'
          : 'carousel__slide'
      }
    >
      <LazyLoadImage
        alt={props.alt}
        threshold={200}
        visibleByDefault={
          props.index === props.activeIndex
        }
        loading={'lazy'}
        // visibleByDefault={
        //   props.index === props.activeIndex && regex.test(userAgent)
        // }
        // placeholderSrc={blurredImage}
        delayTime={300}
        effect='opacity'
        width={'100%'}
        src={props.slide} // use normal <img> attributes as props
      />
      {/* <img alt='mline' src={props.slide} /> */}
    </li>
  );
};

const CarouselIndicator = (props) => {
  return (
    <li>
      <a
        className={
          props.index === props.activeIndex
            ? 'carousel__indicator carousel__indicator--active'
            : 'carousel__indicator'
        }
        onClick={props.onClick}
      />
    </li>
  );
};

export default Carousel;
