import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

const trackClickAnalytics = (category, action, label, value) => {
    // Lets track that custom click
    trackCustomEvent({
      // string - required - The object that was interacted with (e.g.video)
      category,
      // string - required - Type of interaction (e.g. 'play')
      action,
      // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
      label,
      // number - optional - Numeric value associated with the event. (e.g. A product ID)
      value,
    });
}

export default trackClickAnalytics;